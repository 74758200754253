<!-- login -->
<template>
  <div class="login-page" :style="{backgroundImage: `url(${logonBackgroundMap})`}">
    <ics-page-header :project-title="projectTitle" :layout="[]" />
    <div v-if="active === 0" class="find-pass-word-inner">
      <div class="find-pass-word-content">
        <div class="partition-area" style="margin-top: 10px;">
          <el-form ref="appForm" :model="appForm" :rules="rules" label-width="140px" label-position="right">
            <div class="detail-area">
              <div class="area-title">
                <p class="title">
                  选择管理员身份
                </p>
              </div>
              <div class="detail-inner">
                <el-row :gutter="80">
                  <el-col :span="24">
                    <el-form-item label="管理员身份：" prop="account" label-width="170px">
                      <el-radio-group v-model="appForm.userType" @change="changeUserType">
                        <el-radio :label="1">
                          我是法定代表人
                        </el-radio>
                        <el-radio :label="2">
                          我是企业授权委托人
                        </el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                  <el-col v-if="appForm.userType === 2" :span="24">
                    <el-form-item label="授权委托书：" prop="clientGrandUrl" label-width="170px">
                      <el-upload
                        class="avatar-uploader"
                        :action="fileUploadUrl"
                        :headers="headers"
                        :show-file-list="false"
                        :on-success="clientGrandUrlSuccess"
                        :on-error="onError"
                        :before-upload="clientGrandUrlUpload"
                      >
                        <img v-if="appForm.clientGrandUrl" :src="appForm.clientGrandUrl" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon" />
                        <div slot="tip" class="el-upload__tip">
                          温馨提示：您需点击下载<a href="/template/授权委托书模板.docx" target="_blank">《授权委托书》模板</a>，模板或者发送到下方邮箱地址，将模板中的内容复制自行打印，盖章后上传。
                        </div>
                      </el-upload>
                    </el-form-item>
                  </el-col>
                  <el-col v-if="appForm.userType === 2" :span="24">
                    <el-form-item label="电子邮件：" prop="files" label-width="170px">
                      <el-input v-model="appForm.email" style="width: 30%;" placeholder="请输入电子邮箱" />
                      <debounce-button v-if="showTime === false" :loading="loading.smsCode" style="padding: 10px 15px !important;vertical-align: middle;height: 32px;" @click="sendEmailVerificationSms">
                        发送委托书
                      </debounce-button>
                      <el-button v-else style="padding: 10px 15px !important;vertical-align: middle; height: 32px;">
                        {{ time }}S后重发
                      </el-button>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </div>
            <div v-if="isUserType" class="detail-area">
              <div class="area-title">
                <p class="title">
                  选择上传管理员身份照片
                </p>
              </div>
              <div class="detail-inner">
                <el-row :gutter="80">
                  <el-col :span="12">
                    <el-form-item label="姓名：" prop="administratorsName" label-width="170px">
                      <el-input v-model="appForm.administratorsName" class="input_w80" placeholder="请输入姓名" />
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="证件类型：" prop="documentType" label-width="170px">
                      <el-select v-model="appForm.documentType" placeholder="请选择证件类型" style="width: 100%" filterable>
                        <el-option v-for="item in constants.documentType" :key="item.value" :label="item.label" :value="item.value" />
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="证件号码：" prop="idCardNo" label-width="170px">
                      <el-input v-model="appForm.idCardNo" class="input_w80" placeholder="请输入证件号码" />
                    </el-form-item>
                  </el-col>
                  <el-col :span="24">
                    <el-form-item label="法定代表人身份证照片：" required label-width="170px">
                      <el-row :gutter="10">
                        <el-col :span="7">
                          <el-form-item prop="idCardUrlZ">
                            <el-upload
                              class="avatar-uploader"
                              :action="fileUploadUrl"
                              :headers="headers"
                              :show-file-list="false"
                              :on-success="idCardUrlZSuccess"
                              :before-upload="idCardUrlZUpload"
                            >
                              <img v-if="appForm.idCardUrlZ" :src="appForm.idCardUrlZ" class="avatar">
                              <i v-else class="el-icon-plus avatar-uploader-icon" />
                              <div slot="tip" class="el-upload__tip">
                                上传身份证正面
                              </div>
                            </el-upload>
                          </el-form-item>
                        </el-col>
                        <el-col :span="7" :pull="2">
                          <el-form-item prop="idCardUrlF">
                            <el-upload
                              class="avatar-uploader"
                              :action="fileUploadUrl"
                              :headers="headers"
                              :show-file-list="false"
                              :on-success="idCardUrlFSuccess"
                              :before-upload="idCardUrlFUpload"
                            >
                              <img v-if="appForm.idCardUrlF" :src="appForm.idCardUrlF" class="avatar">
                              <i v-else class="el-icon-plus avatar-uploader-icon" />
                              <div slot="tip" class="el-upload__tip">
                                上传身份证反面
                              </div>
                            </el-upload>
                          </el-form-item>
                        </el-col>
                      </el-row>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </div>
            <div v-else class="detail-area">
              <div class="area-title">
                <p class="title">
                  选择上传管理员身份照片
                </p>
              </div>
              <div class="detail-inner">
                <el-row :gutter="80">
                  <el-col :span="12">
                    <el-form-item label="姓名：" prop="administratorsName" label-width="170px">
                      <el-input v-model="appForm.administratorsName" class="input_w80" placeholder="请输入姓名" />
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="证件类型：" prop="documentType" label-width="170px">
                      <el-select v-model="appForm.documentType" placeholder="请选择证件类型" style="width: 100%" filterable>
                        <el-option v-for="item in constants.documentType" :key="item.value" :label="item.label" :value="item.value" />
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="80">
                  <el-col :span="12">
                    <el-form-item label="" label-width="170px" />
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="证件号码：" prop="idCardNo" label-width="170px">
                      <el-input v-model="appForm.idCardNo" class="input_w80" placeholder="请输入证件号码" />
                    </el-form-item>
                  </el-col>
                  <el-col :span="24">
                    <el-form-item label="法定代表人身份证照片：" required label-width="170px">
                      <el-row :gutter="10">
                        <el-col :span="7">
                          <el-form-item prop="idCardUrlZ">
                            <el-upload
                              class="avatar-uploader"
                              :action="fileUploadUrl"
                              :headers="headers"
                              :show-file-list="false"
                              :on-success="idCardUrlZSuccess"
                              :before-upload="idCardUrlZUpload"
                            >
                              <img v-if="appForm.idCardUrlZ" :src="appForm.idCardUrlZ" class="avatar">
                              <i v-else class="el-icon-plus avatar-uploader-icon" />
                              <div slot="tip" class="el-upload__tip">
                                上传身份证正面
                              </div>
                            </el-upload>
                          </el-form-item>
                        </el-col>
                        <el-col :span="7" :pull="2">
                          <el-form-item prop="idCardUrlF">
                            <el-upload
                              class="avatar-uploader"
                              :action="fileUploadUrl"
                              :headers="headers"
                              :show-file-list="false"
                              :on-success="idCardUrlFSuccess"
                              :before-upload="idCardUrlFUpload"
                            >
                              <img v-if="appForm.idCardUrlF" :src="appForm.idCardUrlF" class="avatar">
                              <i v-else class="el-icon-plus avatar-uploader-icon" />
                              <div slot="tip" class="el-upload__tip">
                                上传身份证反面
                              </div>
                            </el-upload>
                          </el-form-item>
                        </el-col>
                      </el-row>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-form>
        </div>
      </div>
      <el-row :gutter="80">
        <el-col :span="24" style="padding: 0px 0px 20px;margin:0 auto; text-align: center; margin-top: 20px;">
          <debounce-button :loading="loading.submit" style="width: 10%; height: 40px; font-size: 14px;" type="primary" @click="distinguish">
            身份识别
          </debounce-button>
        </el-col>
      </el-row>
    </div>
    <div v-if="active === 1" class="find-pass-word-inner1">
      <div class="find-pass-word-content1">
        <div class="partition-area1" style="margin-top: 10px;">
          <el-form>
            <el-row v-if="config.url" :gutter="80" type="flex" justify="center">
              <p style="font-size: 16px; color: rgba(47,46,46,0.81)">
                扫描下方二维码进行人脸识别
              </p>
            </el-row>
            <br>
            <el-row :gutter="80" type="flex" justify="center">
              <div id="qrCode" ref="qrCodeDiv" class="qrCodeDiv" />
            </el-row>
          </el-form>
        </div>
      </div>
      <el-row :gutter="80" style="margin-top: 10px;">
        <el-col :span="24" style="padding: 0px 0px 20px;margin:0 auto; text-align: center; margin-top: 20px;">
          <debounce-button :loading="loading.submit" style="width: 10%; height: 40px; font-size: 14px;" type="primary" @click="commit">
            完成
          </debounce-button>
        </el-col>
      </el-row>
    </div>
    <div v-if="active === 2" class="find-pass-word-inner">
      <div class="find-pass-word-content1">
        <div class="partition-area" style="width: 46%; margin-left:27%;margin-top: 10px;">
          <el-form ref="toForm" :model="toForm" :rules="toRules" label-width="110px" label-position="right">
            <div class="detail-area">
              <div class="area-title">
                <p class="title">
                  请授权平台为您开通电子签章服务，以便您签署电子签章合同
                </p>
              </div>
              <div class="detail-inner">
                <el-row :gutter="70" type="flex" justify="center" align="middle">
                  <el-col :span="16">
                    <el-form-item label="管理员手机号码：" prop="administratorsName">
                      <p>{{ utils.isEffectiveCommon(accountDetail.mobile) }}</p>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="80" type="flex" justify="center" align="middle">
                  <el-col :span="16">
                    <el-form-item prop="smsCode" label="验证码：">
                      <el-input v-model="toForm.smsCode" style="margin-left:-1px !important;width: 61%;" type="number" placeholder="请输入验证码" />
                      <debounce-button v-if="showTime === false" style="padding: 10px 5px !important;vertical-align: middle;margin-top: -2px;width: 92px;" @click="sendVerificationSms">
                        获取验证码
                      </debounce-button>
                      <el-button v-else style="padding: 9px 15px !important;vertical-align: middle;">
                        {{ time }}S后重发
                      </el-button>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="80" style="margin-top: 10px;">
                  <el-col :span="24" style="padding: 0px 0px 20px;margin:0 auto; text-align: center; margin-top: 20px;">
                    <debounce-button :loading="loading.submit" style="width: 10%; height: 40px; font-size: 14px;" type="primary" @click="commitMobile">
                      完成
                    </debounce-button>
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/assets/js/api'
import {isIdentityId} from '../../../components/template/validate'
import QRCode from 'qrcodejs2'
import {cookie} from "../../../assets/js/config";
import IcsPageHeader from '@/components/page-header'
export default {
  components: { IcsPageHeader },
  data () {
    const checkIdentitytionId  = (rule, value, callback) => {
      if (Number(this.appForm.documentType) === 1) {
        let errorMsg = isIdentityId(value);
        if (errorMsg != '') {
          callback(new Error(errorMsg));
        } else {
          callback()
        }
      } else if (Number(this.appForm.documentType) === 2) {
        const passportReg = /^\d{8}|^[a-zA-Z0-9]{10}|^\d{18}$/
        if (passportReg.test(value)) {
          callback()
        } else {
          callback(new Error('护照号码格式不正确'))
        }
      } else if (Number(this.appForm.documentType) === 3) {
        callback()
      } else if (Number(this.appForm.documentType) === 4) {
        callback()
      } else {
        callback()
      }
    }
    return {
      fileUploadUrl: process.env.VUE_APP_REQUEST_BASE_URL + '/capital/open/upload?bucket=concat',
      projectTitle: '',
      headers: {
        Token: this.$store.state.token
      },
      logonBackgroundMap: '',
      contractId: this.$route.query.contractId,
      accountDetail: {},
      showTime: false,
      active: 0,
      time: 120,
      theType: false,
      saveBasicsFirmInfo: {
        firmAccountInfo: {}
      },
      config: {
        url: ''
      },
      isUserType: true,
      appForm: {
        userType: 1,
        administratorsName: '',
        email: '',
        documentType: 1,
        idCardNo: '',
        idCardUrlZ: '',
        idCardUrlF: '',
        clientGrandUrl: ''
      },
      toForm: {
        smsCode: ''
      },
      dialog: {
        title: '信息确认',
        visible: false
      },
      loading: {
        submit: false,
        smsCode: false,
        qrCode: false
      },
      rules: {
        clientGrandUrl: [
          { required: true, message: '请上传授权委托书', trigger: 'change' }
        ],
        administratorsName: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        documentType: [
          { required: true, message: '请选择证件类型', trigger: 'change' }
        ],
        idCardNo: [
          { required: true, message: '请输入证件号码', trigger: 'blur' },
          { validator: checkIdentitytionId , trigger: "blur" }
        ],
        idCardUrlZ: [
          { required: true, message: '请上传身份证正面', trigger: 'change' }
        ],
        idCardUrlF: [
          { required: true, message: '请上传身份证反面', trigger: 'change' }
        ]
      },
      toRules: {}
    }
  },
  watch: {},
  created () {
    this.getDetail()
    this.getData()
  },
  methods: {
    getData () {
      return new Promise((resolve, reject) => {
        this.utils.getPersonalization().then(result => {
          this.logonBackgroundMap = result.logonPage.backgroundMap
          this.projectTitle = result.global.wangName+process.env.VUE_APP_PROJECT_NAME
          resolve()
        }).catch(e => {
          reject(e)
        })
      })
    },
    getDetail () {
      this.api.cfcaSign.accountDetail().then(result => {
        const data = result.data.data || {}
        this.accountDetail = data
      }).finally(() => {
      })
    },
    changeUserType (val) {
      this.appForm.administratorsName = ''
      this.appForm.email = ''
      this.appForm.idCardNo = ''
      if (val === 1) {
        this.isUserType = true
        this.appForm.administratorsName = this.saveBasicsFirmInfo.firmAccountInfo.name
        this.appForm.email = this.saveBasicsFirmInfo.firmAccountInfo.email
        this.appForm.idCardNo = this.saveBasicsFirmInfo.firmAccountInfo.idCardNo
      } else {
        this.isUserType = false
      }
    },
    idCardUrlZSuccess (response) {
      const data = response.data
      const bucketParams = this.utils.encryptRSA('concat').replace(/[+]/g, '%2B')
      const bucketF = bucketParams.replace(/[/]/g, '%2F')
      const bucketD = bucketF.replace(/[=]/g, '%3D')
      const objectKeyParams = this.utils.encryptRSA(data.path).replace(/[+]/g, '%2B')
      const objectKeyF = objectKeyParams.replace(/[/]/g, '%2F')
      const objectKeyD = objectKeyF.replace(/[=]/g, '%3D')
      data.url = `${data.mtype}?bucketParams=${bucketD}&objectKeyParams=${objectKeyD}`
      this.appForm.idCardUrlZ = data.url
    },
    idCardUrlZUpload (file) {
      const fileSuffix = file.name.substring(file.name.lastIndexOf('.') + 1)
      const whiteList = ['jpg', 'png', 'jpeg']
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message({
          showClose: true,
          message: '上传文件只能是 jpg、png、jpeg格式',
          type: 'warning'
        })
        return false
      }
      const isLt = file.size / 1024 / 1024 < 2
      if (!isLt) {
        this.$message.error('上传文件最大为2M')
        return isLt
      }
    },
    idCardUrlFSuccess (response, file) {
      const data = response.data
      const bucketParams = this.utils.encryptRSA('concat').replace(/[+]/g, '%2B')
      const bucketF = bucketParams.replace(/[/]/g, '%2F')
      const bucketD = bucketF.replace(/[=]/g, '%3D')
      const objectKeyParams = this.utils.encryptRSA(data.path).replace(/[+]/g, '%2B')
      const objectKeyF = objectKeyParams.replace(/[/]/g, '%2F')
      const objectKeyD = objectKeyF.replace(/[=]/g, '%3D')
      data.url = `${data.mtype}?bucketParams=${bucketD}&objectKeyParams=${objectKeyD}`
      this.appForm.idCardUrlF = data.url
    },
    idCardUrlFUpload (file) {
      const fileSuffix = file.name.substring(file.name.lastIndexOf('.') + 1)
      const whiteList = ['jpg', 'png', 'jpeg']
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message({
          showClose: true,
          message: '上传文件只能是 jpg、png、jpeg格式',
          type: 'warning'
        })
        return false
      }
      const isLt = file.size / 1024 / 1024 < 2
      if (!isLt) {
        this.$message.error('上传文件最大为2M')
        return isLt
      }
    },
    clientGrandUrlSuccess (response, file) {
      const data = response.data
      const bucketParams = this.utils.encryptRSA('concat').replace(/[+]/g, '%2B')
      const bucketF = bucketParams.replace(/[/]/g, '%2F')
      const bucketD = bucketF.replace(/[=]/g, '%3D')
      const objectKeyParams = this.utils.encryptRSA(data.path).replace(/[+]/g, '%2B')
      const objectKeyF = objectKeyParams.replace(/[/]/g, '%2F')
      const objectKeyD = objectKeyF.replace(/[=]/g, '%3D')
      data.url = `${data.mtype}?bucketParams=${bucketD}&objectKeyParams=${objectKeyD}`
      this.appForm.clientGrandUrl = data.url
    },
    onError (err, file) {
      this.$message.error(JSON.parse(err.message).message || '上传失败')
    },
    clientGrandUrlUpload (file) {
      const fileSuffix = file.name.substring(file.name.lastIndexOf('.') + 1)
      const whiteList = ['jpg', 'png', 'jpeg']
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message({
          showClose: true,
          message: '上传文件只能是 jpg、png、jpeg格式',
          type: 'warning'
        })
        return false
      }
      const isLt = file.size / 1024 / 1024 < 2
      if (!isLt) {
        this.$message.error('上传文件最大为2M')
        return isLt
      }
    },

    clientGrandUrlRemove () {
      this.appForm.clientGrandUrl = ''
    },
    sendEmailVerificationSms () {
      console.log(this.appForm.email)
      if (!this.appForm.email) {
        this.$message({
          message: '请输入邮箱地址',
          type: 'warning'
        })
        return false
      }
      const data = {
        firmId: this.firmId,
        firmName: this.firmName,
        to: this.appForm.email
      }
      api.open.sendEmailTemplate(data).then(result => {
        if (result.data.success === true) {
          this.$message({
            message: '发送成功',
            type: 'success'
          })
          this.showTime = true
          this.timer = setInterval(() => {
            if (this.time === 0) {
              clearInterval(this.timer)
              this.time = 120
              this.showTime = false
            } else {
              this.time--
            }
          }, 1000)
        }
      }).finally(() => {
      })
    },
    sendVerificationSms () {
      if (!this.accountDetail.mobile) {
        this.$message.error('该账号目前未匹配到相关管理员手机号')
        return false
      }
      this.loading.smsCode = true
      api.cfcaSign.accountSmsSend().then(result => {
        this.$message({
          message: '发送成功',
          type: 'success'
        })
        this.showTime = true
        this.timer = setInterval(() => {
          if (this.time === 0) {
            clearInterval(this.timer)
            this.time = 300
            this.showTime = false
          } else {
            this.time--
          }
        }, 1000)
      }).finally(() => {
        this.loading.smsCode = false
      })
    },
    distinguish () {
      this.$refs.appForm.validate((valid) => {
        if (valid) {
          this.loading.submit = true
          let type = this.appForm.userType
          let data = {}
          if (type === 1) {
            data.type = 1
            data.idCardUrlZ = this.appForm.idCardUrlZ
            data.idCardUrlF = this.appForm.idCardUrlF
            data.name = this.appForm.administratorsName
            data.idCardType = this.appForm.documentType
            data.idCardNo = this.appForm.idCardNo
            data.email = this.appForm.email
          } else {
            data.type = 2
            data.clientGrandUrl = this.appForm.clientGrandUrl
            data.name = this.appForm.administratorsName
            data.idCardType = this.appForm.documentType
            data.idCardNo = this.appForm.idCardNo
            data.idCardUrlZ = this.appForm.idCardUrlZ
            data.idCardUrlF = this.appForm.idCardUrlF
          }
          if (this.accountDetail.id) {
            data.id = this.accountDetail.id
          }
          api.cfcaSign.addContractAccount(data).then(result => {
            if (result.data.success === true) {
              this.$message.success('您的认证资料已提交')
              this.loading.submit = false
              this.obtainBindQRCode()
              this.active = 1
            } else {
              this.$message.error(result.data.message)
              this.loading.submit = false
            }
          }).catch(e => {
            this.loading.submit = false
          })
        }
      })
    },
    obtainBindQRCode () {
      this.loading.qrCode = true
      let wPath = window.document.location.href
      let pathName =  this.$route.path
      let pos = wPath.indexOf(pathName)
      let localhostPath = wPath.substring(0, pos)
      const data = {
        redirectUrl: `${localhostPath}/api/capital`
      }
      api.cfcaSign.faceAuth(data).then(result => {
        this.loading.qrCode = false
        this.config.url = result.data.data
        this.$nextTick(function () {
          this.bindQRCode()
        })
      }).finally(() => {
        this.loading.qrCode = false
      })
    },
    step () {
      this.active = 0
      this.theType = true
      this.getDetail()
    },
    commit () {
      this.loading.submit = true
      api.cfcaSign.responseTrue().then(result => {
        if (result.data.data) {
          if (this.accountDetail.state === 0) {
            this.$message.success('已完成人脸识别')
            this.loading.submit = false
            this.active = 2
          } else if (this.accountDetail.state === 1) {
            this.$router.push({ name: 'signDetail', query: { contractId: this.contractId } })
          } else {
            return false
          }
        } else {
          this.$message.error('未完成人脸识别,请先扫描二维码进行人脸识别')
          return false
        }
      }).finally(() => {
        this.loading.submit = false
      })
    },
    commitMobile () {
      if (!this.toForm.smsCode) {
        this.$message.error('请输入验证码')
        return false
      }
      this.loading.submit = true
      api.cfcaSign.accountSmsValidCode(this.toForm.smsCode).then(result => {
        if (result.data.success === true) {
          this.$message.success('授权成功')
          this.loading.submit = false
          this.$router.push({ name: 'signDetail', query: { contractId: this.contractId } })
        } else {
          this.$message.error(result.data.message)
          this.loading.submit = false
        }
      })
    },
    bindQRCode: function () {
      console.log(this.config.url)
      var qrcodeCreater = new QRCode(this.$refs.qrCodeDiv, {
        text: this.config.url,
        width: 300,
        height: 300,
        colorDark: "#000000", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L//容错率，L/M/H
      })
      qrcodeCreater._el.title = ''
    },
    previewFile (file) {
      this.utils.downloadFile(file.url, file.name)
    }
  }
}
</script>

<style lang="less" scoped>
.login-page {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #FFFFFF;
  background-size: 100% 100%;
}
.find-pass-word-inner {
  width: 99%;
  height: 620px;
  border-radius: 5px;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  margin-top: -315px;
  margin-left: 5px;
  padding: 40px 30px 40px;
  .logo-inner {
    margin: 0 auto 10px;
    width: 380px;
    height: 30px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  h2 {
    font-size:25px;
    line-height: 1;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    margin-bottom: 30px;
  }
  .find-pass-word-content {
    background: #fff;
    margin-top: 10px;
    height: 530px;
    overflow:auto;
    overflow-x:hidden;
    .find-pass-word-content-title {
      border-bottom: 1px solid lavender;
    }
  }
  .find-pass-word-content1 {
    margin-top: 30px;
    height: 400px;
    overflow:auto;
    overflow-x:hidden;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
    border: 1px dashed #d9d9d9;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .partition-area1 {
    margin: 24px;
  }
}
.find-pass-word-inner1 {
  width: 840px;
  height: 550px;
  border-radius: 5px;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -410px;
  margin-top: -245px;
  padding: 40px 30px 40px;
  background-color: rgba(0, 0, 0, 0.6);
  .logo-inner {
    margin: 0 auto 10px;
    width: 380px;
    height: 30px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  h2 {
    font-size:25px;
    line-height: 1;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    margin-bottom: 30px;
  }
  .find-pass-word-content {
    background: #fff;
    margin-top: 10px;
    height: 530px;
    overflow:auto;
    overflow-x:hidden;
    .find-pass-word-content-title {
      border-bottom: 1px solid lavender;
    }
  }
  .find-pass-word-content1 {
    margin-top: 30px;
    height: 400px;
    overflow:auto;
    overflow-x:hidden;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
    border: 1px dashed #d9d9d9;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .partition-area1 {
    margin: 24px;
  }
}
</style>
<style scoped>
.el-step >>> .el-step__icon {
  width: 40px !important;
  height: 40px !important;
}
.el-step >>> .el-step__line{
  top: 18px !important;
}
.el-step >>>  .is-process .el-step__icon-inner {
  color: #000 !important;
}
.el-step >>> .is-process{
  color: #fff !important;
}
</style>
